import axios from 'axios'

const BASE_URL = window.location.origin ?? window.app_url

const apiClient = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

export default apiClient
